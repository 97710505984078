function wdSubmit( evt , f , ae = false  ) {
    //console.log( f.name , '>' , document.activeElement.name );
    const a = (ae !== false )  ? ae :  document.activeElement.name ;
    console.log( 'a' , '=' , a ) ;
    window.localStorage.setItem( "ae", a  );
    f.submit() ;
 }

$(document).ready(function(){
    const kae = window.localStorage.getItem( "ae" ) ;
    if ( kae ) {
        $('[name^=' + kae + ']').focus() ;
        window.localStorage.setItem("ae", false);

    }

});